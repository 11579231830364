import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    get(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/user/get/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    save(ctx, {
      id, addressData, userData, sepaData,
    }) {
      return new Promise((resolve, reject) => {
        const postData = { address: addressData.value, user: userData.value, sepa: sepaData.value }
        axios
          .post(`/user/update/${id}`, postData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editUser(ctx, userData) {
      console.log(userData)
      return new Promise((resolve, reject) => {
        axios
          .post('/user/basic-info-edit', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
